import styled from "styled-components";
import Button from "@mui/material/Button";
export const CTAButton = styled(Button)`
  width: 100%;
  height: 60px;
  margin-top: 10px;
  margin-bottom: 5px;
  background: linear-gradient(180deg, #604ae5 0%, #813eee 100%);
  color: white;
  font-size: 16px;
  font-weight: bold;
`; // add your own styles here
import { loadState } from './store/localStorage'
import { useState, useEffect } from "react";

export const CustomBackground = () => {
    const [image_source, setImageSource] = useState('')
    useEffect(() => {
        console.log('loadState().Data.brackground', loadState().Data.collection);

        if (loadState().Data && loadState().Data.collection) {
            setImageSource(loadState().Data.collection.brackground)
        }

    }, [])
    useEffect(() => {
        console.log('image_source', image_source);

    }, [image_source])
    return (
        <section>
            {
                image_source && image_source.length > 0 && (
                     <div className="custom-background" style={{ backgroundImage: `url(${image_source})` }}>
                     </div>
                    // <img src={image_source} className="custom-background" style={{ background: "url(" + { image_source } + ")" }}>

                    // </img>
                )
            }
        </section >
    );
};

