
import image_source from './assets/img/NeefterSVG6.svg'
export const TicketHedaer = () => {

    return (
        <section className="ticket-header">
            <div className="ticket-header-image" style={{ backgroundImage: `url(${image_source})` }}>
            </div>
        </section >
    );
};
