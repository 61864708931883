import "./App.css";
import { useMemo, useEffect, useState } from "react";
import * as anchor from "@project-serum/anchor";
import Home from "./Home";
import { DEFAULT_TIMEOUT } from "./connection";
import { clusterApiUrl } from "@solana/web3.js";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";

// import { Helmet } from "react-helmet";
import { Helmet, HelmetProvider } from 'react-helmet-async';

import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletDialogProvider } from "@solana/wallet-adapter-material-ui";

import { createTheme, ThemeProvider } from "@mui/material";
import { PhantomWalletAdapter } from "@solana/wallet-adapter-phantom";
import { SlopeWalletAdapter } from "@solana/wallet-adapter-slope";
import { SolflareWalletAdapter } from "@solana/wallet-adapter-solflare";
import {
  SolletWalletAdapter,
  SolletExtensionWalletAdapter,
} from "@solana/wallet-adapter-sollet";
import axios from 'axios'
import { setActiveCollection, updateCollection } from './store/actions'

import { connect, useDispatch } from "react-redux"
import { CustomBackground } from './Custom'
import { loadState } from './store/localStorage'
import { CustomLogo } from "./CustomLogo";
import { CustomFooter } from "./CustomFooter";
import { TicketFooter } from "./TicketFooter";
import { TicketHedaer } from "./TicketHeader";
import TicketHome from "./TicketHome";

import favicon from "./assets/favicon/favicon-16x16.png"
import location from './assets/img/octicon_location-16.svg'
import lines from './assets/img/Lines.svg'

const theme = createTheme({
  palette: {
    mode: "dark",
  },
});

const getCandyMachineId = (candyMachineId: any): anchor.web3.PublicKey | undefined => {
  try {
    return new anchor.web3.PublicKey(candyMachineId!);
  } catch (e) {
    console.log("Failed to construct CandyMachineId", e);
    return undefined;
  }
};

let error: string | undefined = undefined;

if (process.env.REACT_APP_SOLANA_NETWORK === undefined) {
  error =
    "Your REACT_APP_SOLANA_NETWORK value in the .env file doesn't look right! The options are devnet and mainnet-beta!";
} else if (process.env.REACT_APP_SOLANA_RPC_HOST === undefined) {
  error =
    "Your REACT_APP_SOLANA_RPC_HOST value in the .env file doesn't look right! Make sure you enter it in as a plain-text url (i.e., https://metaplex.devnet.rpcpool.com/)";
}

const network = (process.env.REACT_APP_SOLANA_NETWORK ??
  "devnet") as WalletAdapterNetwork;
const rpcHost =
  process.env.REACT_APP_SOLANA_RPC_HOST ?? anchor.web3.clusterApiUrl("devnet");
const connection = new anchor.web3.Connection(rpcHost);

const App = () => {
  const [candyMachine, setCandyMachine] = useState<anchor.web3.PublicKey | undefined>(undefined)
  const [data, setData] = useState(undefined)
  const [crossmintId, setCrossmintId] = useState(undefined)
  const [name, setName] = useState('')
  const [image, setImage] = useState(undefined)
  const [description, setDescription] = useState('')
  const [faviconRemote, setFaviconRemote] = useState(undefined)
  const [price, setPrice] = useState(undefined)
  const [network_config, setNetworkConfig] = useState<any>()

  const [event_title, setEventTitle] = useState('')
  const [event_organizer, setEventOrganizer] = useState('')
  const [event_venue, setEventVenue] = useState('')
  const [event_venue_address, setEventVenueAddress] = useState('')
  const [event_start_date, setEventStartDate] = useState('')
  const [event_end_date, setEventEndDate] = useState('')


  const [alternative_view, setAlternativeView] = useState(false)
  const dispatch = useDispatch();

  useEffect(() => {
    if(window.localStorage.getItem('walletName')) {
      window.location.reload();
    }

    (async () => {
      try {
        const hostname = window && window.location && window.location.hostname
        const subdomain = hostname.split('.')[0]
        // const subdomain = 'xolos'
        const response = await axios.get(`https://api.neefter.com/v1/public/collection/${subdomain}`)

        if (response.data.errors) {
          // toastr or something
          return
        }

        console.log(candyMachine)
        setData(response.data)
        // setAlternativeView(response.data.)

      } catch (error) {
        console.log(error)
      }
    })()
  }, [])
  useEffect(() => {
    if (!data) return;
    console.log('data', data);
    dispatch(updateCollection(data));
    console.log('loadState', loadState());
    setCandyMachineFromEffect(data);
  }, [data])

  const setCandyMachineFromEffect = async (data: any) => {
    if (!data) return;
    console.log('data.type_id', (data.type_id + ''));
    console.log((data.type_id + '') === '5');
    if ((data.type_id + '') === '5') {
      console.log('data?.collection_info.candy_machine_address', data?.collection_info.candy_machine_address);
      const candyMachine = await getCandyMachineId(data?.collection_info.candy_machine_address)
      setCrossmintId(data?.collection_info.crossmint_id)
      setCandyMachine(candyMachine)
      setName(data.name)
      setDescription(data.description)
      setFaviconRemote(data.collection_info.favicon)
      setImage(data.logo)
      setNetworkConfig(data.collection_info.network_config)
      setAlternativeView(true)

      setEventTitle(data.event_info.event_title)
      setEventOrganizer(data.event_info.event_organizer)
      setEventVenue(data.event_info.event_venue)
      setEventVenueAddress(data.event_info.event_venue_address)
      setEventStartDate(data.event_info.event_start_date)
      setEventEndDate(data.event_info.event_end_date)
      setPrice(data.collection_info.price)

    } else {
      const candyMachine = await getCandyMachineId(data?.candy_machine_address)
      setCrossmintId(data?.crossmint_id)
      setCandyMachine(candyMachine)
      setName(data.name)
      setDescription(data.description)
      setFaviconRemote(data.favicon)
      setImage(data.image_id)
      setPrice(data.price)
      setNetworkConfig(data.network_config)
    }

  }

  console.log(`CANDY MACHINE ________`, candyMachine)

  const endpoint = useMemo(() => clusterApiUrl(network), []);

  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new SolflareWalletAdapter({ network }),
      new SlopeWalletAdapter(),
      new SolletWalletAdapter({ network }),
      new SolletExtensionWalletAdapter({ network }),
    ],
    []
  );

  return (
    <ThemeProvider theme={theme}>
      <ConnectionProvider endpoint={endpoint}>
        <WalletProvider wallets={wallets} autoConnect>
          <WalletDialogProvider>
            <HelmetProvider>
              <Helmet>‍
                <title>{name}</title>
                <link rel="icon" href={faviconRemote || favicon} />
                <link rel="shortcut icon" href={faviconRemote || favicon} />
                <meta name="description" content={description} />
                <meta property="og:locale" content="en_US" />
                <meta name="og:title" content={name} />
                ‍
              </Helmet>
            </HelmetProvider>

            {
              alternative_view && (
                <>
                  <TicketHedaer></TicketHedaer>
                  <div className="ticket">
                    <div className="ticket-img">
                      {
                        image && (
                          <div className="ticket-img-detail" style={{ backgroundImage: `url(${image})` }}>
                          </div>
                        )
                      }
                    </div>
                    <div className="ticket-content">
                      <div className="ticket-content-head">
                        <div className="ticket-content-head-left">
                          <p className="title">{
                            event_title
                          }</p>
                          <p className="subtitle">Organized by <b>@{event_organizer}</b></p>
                        </div>
                      </div>
                      <div className="ticket-content-info">
                        <div className="ticket-content-info-date">
                          <img src={lines}></img>
                          <p>
                            {event_start_date}<br />
                            {event_end_date}
                          </p>
                        </div>
                        <div className="ticket-content-info-date">
                          <img src={location}></img>
                          <p>
                            {event_venue}, {event_venue_address}
                          </p>
                        </div>
                      </div>
                      <div className="ticket-content-description">
                        <div className="ticket-content-description-title">
                          Description
                        </div>
                        <div className="ticket-content-description-body">
                          {
                            description
                          }
                        </div>
                      </div>
                      <div className="ticket-content-currency">
                        {price} {network_config ? network_config.native_token : ''}
                      </div>
                      <div className="ticket-content-connect" style={{ zIndex: 50 }}>
                        {(candyMachine) && (
                          <TicketHome
                            candyMachineId={candyMachine}
                            crossmintId={crossmintId}
                            connection={connection}
                            txTimeout={DEFAULT_TIMEOUT}
                            rpcHost={rpcHost}
                            network={network}
                            error={error}
                          />

                        )}


                      </div>
                    </div>
                    <div className="ticket-mobile">
                      <div className="ticket-content-head">
                        <div className="ticket-content-head-left">
                          <p className="title">{
                            event_title
                          }</p>
                          <p className="subtitle">Organized by <b>@{event_organizer}</b></p>
                        </div>
                      </div>
                      <div className="ticket-content-info">
                        <div className="ticket-content-info-date">
                          <img src={lines}></img>
                          <p>
                            {event_start_date}<br />
                            {event_end_date}
                          </p>
                        </div>
                        <div className="ticket-content-info-date">
                          <img src={location}></img>
                          <p>
                            {event_venue}, {event_venue_address}
                          </p>
                        </div>
                      </div>
                      <div className="ticket-content-img">
                        {
                          image && (
                            <div className="ticket-content-img-detail" style={{ backgroundImage: `url(${image})` }}>
                            </div>
                          )
                        }
                      </div>
                      <div className="ticket-content-description">
                        <div className="ticket-content-description-title">
                          Description
                        </div>
                        <div className="ticket-content-description-body">
                          {
                            description
                          }
                        </div>
                      </div>
                      <div className="ticket-content-currency">
                        {price} {network_config ? network_config.native_token : ''}
                      </div>
                      <div className="ticket-content-connect" style={{ zIndex: 50 }}>
                        {(candyMachine) && (
                          <TicketHome
                            candyMachineId={candyMachine}
                            crossmintId={crossmintId}
                            connection={connection}
                            txTimeout={DEFAULT_TIMEOUT}
                            rpcHost={rpcHost}
                            network={network}
                            error={error}
                          />

                        )}


                      </div>
                    </div>
                  </div>


                  <TicketFooter></TicketFooter>
                </>
              )
            }
            {
              !alternative_view && (
                <>
                  {candyMachine && (
                    <CustomBackground></CustomBackground>
                  )}
                  {candyMachine && (
                    <CustomLogo></CustomLogo>
                  )}
                  {candyMachine && (
                    <Home
                      candyMachineId={candyMachine}
                      crossmintId={crossmintId}
                      connection={connection}
                      txTimeout={DEFAULT_TIMEOUT}
                      rpcHost={rpcHost}
                      network={network}
                      error={error}
                    />
                  )}
                  {candyMachine && (
                    <CustomFooter></CustomFooter>
                  )}
                </>
              )
            }


          </WalletDialogProvider>
        </WalletProvider>
      </ConnectionProvider>
    </ThemeProvider>
  );
};

const mapStatetoProps = (state: any) => {
  const { Collection } = state
  return { Collection }

}

export default
  connect(
    mapStatetoProps,
    { setActiveCollection, updateCollection }
  )((App)
  )