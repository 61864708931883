
import {
    SET_ACTIVE_COLLECTION,
    UPDATE_COLLECTION
} from "./actionTypes"

export const setActiveCollection = (collection: any) => {
    return {
        type: SET_ACTIVE_COLLECTION,
        data: collection
    }
}

export const updateCollection = (collection: any) => {
    return {
        type: UPDATE_COLLECTION,
        data: collection
    }
}