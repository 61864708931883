import styled from "styled-components";
import Button from "@mui/material/Button";
import image_source from './assets/img/NeefterSVG6.svg'
export const CTAButton = styled(Button)`
  width: 100%;
  height: 60px;
  margin-top: 10px;
  margin-bottom: 5px;
  background: linear-gradient(180deg, #604ae5 0%, #813eee 100%);
  color: white;
  font-size: 16px;
  font-weight: bold;
`; // add your own styles here
export const CustomFooter = () => {
    // const image_source = 'src/assets/img/NeefterSVG6.svg'

    return (
        <section>
            <div className="custom-footer">

                <a href="https://twitter.com/Neefter_">
                    Powered by  <img src={image_source}></img>
                </a>
            </div>
        </section >
    );
};

