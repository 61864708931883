import styled from "styled-components";
import Button from "@mui/material/Button";
import { useEffect, useState } from "react";
export const CTAButton = styled(Button)`
  width: 100%;
  height: 60px;
  margin-top: 10px;
  margin-bottom: 5px;
  background: linear-gradient(180deg, #604ae5 0%, #813eee 100%);
  color: white;
  font-size: 16px;
  font-weight: bold;
`; // add your own styles here
import { loadState } from './store/localStorage'

export const CustomLogo = () => {
    const [image_source, setImageSource] = useState(undefined)

    useEffect(() => {
        console.log('loadState', loadState());
        if (loadState().Data && loadState().Data.collection) {
            setImageSource(loadState().Data.collection.logo)
        }

    }, [])
    return (
        <section>
            <div className="custom-logo">
                {
                    image_source && (
                        <img src={image_source}></img>
                    )
                }
            </div>
        </section >
    );
};

