import {
    SET_ACTIVE_COLLECTION,
    UPDATE_COLLECTION
} from "./actionTypes"
import { loadState } from "../../localStorage"

const persistedState = loadState()
const initialState = persistedState ? persistedState?.Projects : {
    collection: null
}

const CollectionReducer = (state = initialState, action: any) => {
    const { data } = action

    switch (action.type) {
        case SET_ACTIVE_COLLECTION:
            state = {
                ...state,
                collection: data
            }

            break
        case UPDATE_COLLECTION:
            return {
                ...state,
                collection: data
            }
        default:
            return { ...state }
    }

    return state
}

export default CollectionReducer
