
export const TicketFooter = () => {

    return (
        <section className="ticket-footer-container">
            <div className="ticket-footer">
                <div className="ticket-footer-left">
                    2023 ©  <span>Neefter Launchpad</span>
                </div>
                <div className="ticket-footer-right">
                    <span>Help</span>
                    <span>Documentation</span>
                    <span>Legal</span>
                </div>
            </div >
        </section>

    );
};
