import { createStore, compose } from "redux"

import rootReducer from "./reducers"
import { loadState, saveState } from './localStorage'

const composeEnhancers = compose
const persistedState = loadState()

const store = createStore(
    rootReducer,
    persistedState,
    composeEnhancers()
)

store.subscribe(() => {
    console.log('saveState', store.getState());

    saveState({
        Data: store.getState().Collection
    })
})


export default store